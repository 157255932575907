body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-player-yellow: #CC9933;
  --color-player-yellow-background: #ECE070;
  --color-player-blue: #163F67;
  --color-player-blue-background: #6390BD;

  --hexagon-default-color: silver;
  --hexagon-hover-color: rgba(255, 255, 255, 0.800);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  margin-top: 100px;
}

nav a {
  color: white;
  text-decoration: none;
  display: block;
  font-size: 30px;
}

nav a:hover {
  color: blueviolet;
}

#azkviz h2 {
  font-size: 45px;
}

#azkviz #legend {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 35px;
  padding: 1em;
}

#azkviz #legend .player-name {
  font-variant: small-caps;
  font-size: 1.25em;
  font-weight: bold;
}

#azkviz #legend .player-name.player-blue {
  color: var(--color-player-blue)
}

#azkviz #legend .player-name.player-yellow {
  color: var(--color-player-yellow)
}

#azkviz #pyramid {
  position: relative; 
  margin: auto;
  width: 770px;
}

#azkviz #background {
  width: 0; 
  height: 0; 
  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  
  border-bottom: 800px solid gray;
  position: absolute;
  top: -120px;
  left: -120px
}

#azkviz #background.player-blue {
  border-bottom-color: var(--color-player-blue-background)
}

#azkviz #background.player-yellow {
  border-bottom-color: var(--color-player-yellow-background)
}

#azkviz .hexagon {
  width: 100px;
  height: 55px;
  background: var(--hexagon-default-color);
  position: absolute;
  cursor: pointer;
}

#azkviz .hexagon:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 25px solid var(--hexagon-default-color);
  cursor: pointer;
}

#azkviz .hexagon:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 25px solid var(--hexagon-default-color);
  cursor: pointer;
}

#azkviz .hexagon.player-blue {
  background: var(--color-player-blue);
}

#azkviz .hexagon.player-blue:before {
  border-bottom-color: var(--color-player-blue);
}

#azkviz .hexagon.player-blue:after {
  border-top-color: var(--color-player-blue);
}

#azkviz .hexagon.player-yellow {
  background: var(--color-player-yellow);
}

#azkviz .hexagon.player-yellow:before {
  border-bottom-color: var(--color-player-yellow);
}

#azkviz .hexagon.player-yellow:after {
  border-top-color: var(--color-player-yellow);
}

#azkviz .hexagon.player-free:hover {
  background: var(--hexagon-hover-color);
}

#azkviz .hexagon.player-free:hover:before {
  border-bottom-color: var(--hexagon-hover-color);
}

#azkviz .hexagon.player-free:hover:after {
  border-top-color: var(--hexagon-hover-color);
}

#azkviz.won .hexagon.player-free {
  background: var(--hexagon-default-color);
}

#azkviz.won .hexagon.player-free:before {
  border-bottom-color: var(--hexagon-default-color);
}

#azkviz.won .hexagon.player-free:after {
  border-top-color: var(--hexagon-default-color);
}

#azkviz .hexagon p.number {
  text-align: center;
  margin: 0;
  line-height: 55px;
  font-size: 40px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

#azkviz .hexagon:hover p.number {
  font-weight: bold;
}

#azkviz.won .hexagon:hover p.number {
  font-weight: normal;
}